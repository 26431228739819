import React from 'react';
import Layout from "../components/Layout";
import { Link } from 'gatsby';

export default function ForPhysicians(){
    return (
        <Layout>
            <div>
            <h1 style={{color:'#3399f3', marginBottom:'30px'}}>For Physicians and Researchers</h1>
            <div class="overflow-auto" style={{fontSize:'1.2em', marginTop:'-10px'}} className='faqContent'>
                <p>Our team has conducted NIH funded research in this community since 1999 with the Brain Attack Surveillance in Corpus Christi (BASIC) Project, an ongoing stroke surveillance study. This project has made many important discoveries about the epidemiology and clinical care of Mexican Americans and non-Hispanic whites with stroke.</p> 
                <p>BASIC Cognitive is a <a href="https://clinicaltrials.gov/ct2/show/NCT03403257" target='_blank' rel='noopener noreferrer' className='callLink'>NIH funded</a> observational non-intervention, no more than minimal risk, study taking place in Nueces County, Texas. This project uses door-to-door surveillance to determine the prevalence and trajectory over time of cognitive impairment and dementia in Mexican Americans and non-Hispanic whites. The study will evaluate the roles of vascular disease risk factors, educational attainment and socio-economic status on cognitive status. It will also assess community resources and caregiving needs to determine gaps between needs and resources that can be remedied.</p> 
                <p>The investigative team have conducted research in this community for 20 years and are experts in Mexican American culture and health, cognitive impairment and dementia, epidemiologic methods, community needs assessment and health behavior research.</p> 
                <p>If you are interested in collaborating or would like to request data, please contact principal investigator <a href="mailto:lmorgens@med.umich.edu" target='_blank' rel='noopener noreferrer' className='callLink'>Lewis Morgenstern, MD</a>.</p> 
                <p><Link to='/publications' className='callLink'>Click here</Link> to view a list of current BASIC Cognitive publications.</p>
                <p>For an extensive list of Brain Attack Surveillance in Corpus Christi (BASIC) Project publications, visit the <a href="https://medicine.umich.edu/dept/neurology/basic-publications" target='_blank' rel='noopener noreferrer' className='callLink'>BASIC Project website</a>.</p> 
            </div>
            </div>
        </Layout>
    )
}